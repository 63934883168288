/** MOBILE FIRST DEVELOPMENT **/

.nav {
  @apply fixed top-0 w-full z-20 bg-primary-50 shadow-[0_3px_6px_0_rgb(0,0,0,0.16)];
}

.container {
  @apply py-3 flex justify-between;
}

.navTopPartContainer {
  @apply flex items-center justify-between;
}

.logo {
  @apply w-9 h-9 cursor-pointer;
}

.iconsContainer {
  @apply flex items-center justify-center pt-4 px-28;
}

.icon {
  @apply w-8 h-8 cursor-pointer;
}

@screen md {
  .navTopPartContainer {
    @apply relative;
  }

  .iconsContainer {
    @apply absolute w-max top-[4%] right-[10%] rtl:right-[52%];
  }

  .iconsContainerUser {
    @apply right-0 rtl:right-auto rtl:left-0 !important;
  }

  .icon {
    @apply w-6 h-6 mr-16 rtl:ml-0;
  }
}

@screen lg {
  .iconsContainer {
    @apply right-[10%] rtl:right-[57.5%];
  }

  .logo {
    @apply w-11 h-11;
  }

  .icon {
    @apply w-7 h-7;
  }
}

@screen xl {
  .container {
    @apply py-5 px-12;
  }

  .iconsContainer {
    @apply top-[13%] right-[8%] rtl:right-[68.5%];
  }
}

@screen 2xl {
  .container {
    @apply py-5 px-28;
  }

  .iconsContainer {
    @apply top-[15%] right-[9.5%];
  }
}

@screen 3xl {
  .logo {
    @apply w-16 h-16;
  }

  .icon {
    @apply w-9 h-9;
  }
}
