/** MOBILE FIRST DEVELOPMENT **/

.form {
  @apply w-full h-full;
}
.main {
  @apply bg-bg_white py-6 mt-[108px] md:mt-[60px] xl:mt-[120px] px-10;
}


.formcontainer {
  @apply h-full flex flex-col items-center justify-evenly text-center;
}

.formHeader {
  @apply flex flex-col items-center;
}

.formInputsContainer {
  direction: initial !important;
  @apply w-full flex justify-center !important;
}

.formInputsContainer div {
  @apply flex justify-evenly w-4/5 !important;
}

.formInputsContainer input {
  @apply font-Raleway text-text_464646 text-5xl text-center font-bold overflow-hidden bg-transparent border-x-0 border-t-0 border-b-2 border-text_464646 rounded-none w-10 md:w-20 lg:w-24 h-32 mt-16 !important;
}

@screen lg {
  .main {
      @apply py-10 px-9 mx-12 shadow-[0_2px_10px_0_rgb(0,0,0,0.16)];
  }
}
