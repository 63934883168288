/* MOBILE FIRST DEVELOPMENT */

.main {
  @apply py-6 mt-[108px] md:mt-[60px] xl:mt-[84px];
}

.container {
  @apply px-6;
}

@screen lg {
  .main {
    @apply py-10 px-9;
  }

  .container {
    @apply px-0;
  }
}

@screen 2xl {
  .main {
    @apply py-16 px-14;
  }
}

@screen 3xl {
  .main {
    @apply py-20;
  }
}
