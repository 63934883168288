/** MOBILE FIRST DEVELOPMENT **/

.container {
  @apply flex flex-col justify-between;
}

@screen lg {
  .container {
    @apply flex-row;
  }
}
