/** MOBILE FIRST DEVELOPMENT **/

.form {
  @apply w-full h-full text-right ltr:text-left;
}

.formInputsContainer {
  @apply mt-6;
}

@screen lg {
  .formInputsContainer {
    @apply mt-5;
  }
}

@screen 3xl {
  .formInputsContainer {
    @apply mt-12;
  }
}
