/* MOBILE FISRT DEVELOPMENT */

.footer {
  @apply bg-bg_f7f7f7 mt-16;
}

.footerMainContainer {
  @apply bg-text_white py-6 px-6 h-28 bg-[#f8f8fe];
}

.newsLetterForm {
  @apply flex flex-col items-center justify-between mt-5;
}

.newsLetterHeading {
  @apply flex flex-col items-center text-center;
}

.logo {
  @apply w-12 h-12 mb-4 cursor-pointer;
}

.footerNavlinksContiner {
  @apply flex flex-col justify-between items-center my-8;
}

.footerNavlinksContiner a {
  @apply mb-6;
}

.footerNavlinksContiner a:last-child {
  @apply mb-0;
}

.socialMediaLinksContainer {
  @apply flex justify-between items-end mx-auto;
}

.socialMedialogo {
  @apply h-12;
}

/* .socialMedialogo:nth-child(2),
.socialMedialogo:nth-child(4) {
  @apply h-6;
} */

.copyRights {
  @apply w-full h-12 flex justify-center  bg-[#bf0022];
}

@screen md {
  .footerMainContainer {
    @apply py-5 px-12;
  }
  .newsLetterHeading {
    @apply w-1/2 mx-auto;
  }
  .footerNavlinksContiner {
    @apply flex-row;
  }
  .newsLetterForm {
    @apply flex-row;
  }
}

@screen lg {
  .footer {
    @apply mt-16;
  }

  .footerMainContainer {
    @apply py-8 px-20;
  }

  .logo {
    @apply w-14 h-14;
  }

  .newsLetterForm,
  .footerNavlinksContiner {
    @apply w-[70%] mx-auto my-10;
  }

  .newsLetterHeading {
    @apply w-5/12 mx-auto;
  }

  .newsLetterForm {
    @apply mt-10;
  }

  .socialMediaLinksContainer {
    @apply w-1/6;
  }

  .copyRights {
    @apply py-2;
  }
}
