/* MOBILE FIRST DEVELOPMENT */

.icon {
  @apply cursor-pointer relative transform -top-1/2 -translate-y-1/2 left-[92%] rtl:left-0 rtl:right-[92%] w-5 h-5 bg-bg_f5f5f5;
}

.MapIcon {
  @apply cursor-pointer relative transform -top-1/2 -translate-y-1/2 ltr:right-0 mx-[10px] w-5 h-5 bg-bg_f5f5f5;
}

.suggestionsList {
  list-style: none;
  max-height: 412px;
  height: fit-content;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  @apply bg-primary-50 rounded-[8px] overflow-y-auto pl-0 mt-0 border-t-0;
}

@screen md {
  .icon {
    @apply  ltr:left-[94%] rtl:right-[94%] w-[28px] h-[28px];
  }

  .MapIcon {
    @apply rounded-[2px] ltr:right-0 mx-[10px] bg-white w-[40px] h-[40px] p-[8px] shadow-[0_1px_5px_-1px_rgb(0,0,0,0.30)];
   }
}

@screen 2xl {
 
  .suggestionsList {
    @apply  max-h-[440px];
  }
}

@screen 3xl {
  .suggestionsList {
    @apply  max-h-[550px];
  }
}

