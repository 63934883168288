/* MOBILE FIRST DEVELOPMENT */

.container {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  @apply h-max w-full min-h-[258px]  rounded-[8px];
}

.restaurantInfoContainer {
  @apply py-[14px] px-3;
}

.restaurantInfoHeader {
  @apply flex justify-between items-center;
}

.restaurantInfoHeader__rating {
  @apply flex items-center font-SFProText;
}

.ratingIcon {
  @apply w-[15px] h-[14px] mr-2;
}

.deliveryIcon {
  @apply w-7 h-4 mr-2 rtl:mr-0 rtl:ml-2;
}

@screen md {
  .container {
    @apply h-max w-[249px] min-h-[260px]  rounded-[8px];
  }
}

@screen 3xl {
  .container {
    @apply w-[311px] min-h-[302px] rounded-[10px];
  }
  .restaurantInfoContainer {
    @apply py-[18px] px-4;
  }
  .ratingIcon {
    @apply mr-[10px];
  }
}
