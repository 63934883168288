/* -- MOBILE FIRST DEVELOPMENT -- */

.container {
  @apply w-screen h-screen overflow-y-hidden bg-center bg-no-repeat bg-cover bg-primary-900;
}

.innerContainer {
  @apply h-screen w-screen grid place-items-center;
}

.glassContainer {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  @apply w-screen h-screen overflow-y-hidden bg-glassBg backdrop-blur-sm py-10  lg:py-14 3xl:py-16;
}

.containerSignup {
  @apply pb-36 pt-24 bg-center bg-no-repeat bg-cover bg-primary-900;
}

.innerContainerSignup {
  @apply grid place-items-center;
}

.glassContainerSignup {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  @apply bg-glassBg backdrop-blur-sm py-10  lg:py-14 3xl:py-16 overflow-hidden;
}
