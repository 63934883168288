/** MOBILE FIRST DEVELOPMENT **/

.button {
  /*  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0 6px 20px 0 rgba(232, 232, 232, 0.5); */
  @apply pt-1 pb-2 backdrop-blur-[30px] cursor-pointer;
}

.primary {
  @apply bg-primary-900 text-text_white text-base font-bold leading-relaxed;
}

.circular {
  border-radius: 50%;
  @apply p-0;
}

.outlined {
  /* -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 6px 20px 0 rgba(232, 232, 232, 0.5); */
  @apply bg-transparent border-[1px];
}

.disabled {
  @apply cursor-not-allowed !important;
}

.goBoxShadow {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
}
