@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.css-mra4m1-indicatorSeparator {
  @apply hidden;
}

.css-kjcdg6-control,
.css-9okibw-control {
  @apply flex-row rtl:flex-row-reverse;
}

.css-1hb7zxy-IndicatorsContainer {
  @apply mr-0 rtl:mr-1;
}

.select__indicators .select__indicator:nth-of-type(2) {
  @apply hidden !important;
}

.css-11vefze-indicatorContainer,
.css-t6j56o-indicatorContainer {
  @apply w-[30px] max-w-[30px] h-[20px] bg-[url(./assets/Icons/dropdownArrow/arrow-down-b.svg)] bg-no-repeat bg-[bottom_50%_left_13px];
}

.css-11vefze-indicatorContainer svg,
.css-t6j56o-indicatorContainer svg {
  @apply hidden;
}

.select__placeholder {
  @apply text-text_bfbfbf !important;
}



html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
  font-weight: 600;
}

button:focus {
  outline: 0;
  /*  outline: 1px auto #fcfcfc; */
}

input[type="checkbox"],
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
}

/* Works on Firefox */
.scroll {
  scrollbar-width: thin;
  scrollbar-color: #bf0022 transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

/* Works on Chrome, Edge, and Safari */

.scroll::-webkit-scrollbar {
  width: 4px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #bf0022;
  border-radius: 4px;
}

/* white scroll bar */
.whiteScroll {
  scrollbar-width: 16px ;
  scrollbar-color: #fff transparent ;
}

.whiteScroll::-webkit-scrollbar {
  width: 16px;
}

.whiteScroll::-webkit-scrollbar-thumb {
  background-color: #fff;
}

.greyScroll {
  scrollbar-width: 10px;
  scrollbar-color: #d8d8d8 transparent;
}

.greyScroll::-webkit-scrollbar {
  width: 10px;
}

.greyScroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}
